import styled from 'styled-components';

export const ProjectsWrapperCss = styled.div`
  padding: 100px 0;

  h1 {
    font-size: 42px;
    margin-bottom: 50px;
    text-align: center;
  }
`;
export const ProjectsPostWrapperCss = styled.div``;

export const LeftSideCss = styled.div`
  width: 35%;
  padding: 15px;
  text-align: center;

  h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
    color: ${props => props.theme.gray900};
  }
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    width: 100%;
    h4 {
      margin-bottom: 50px;
    }
  }
`;
export const RightSideCss = styled.div`
  width: 60%;
  background: ${props => props.theme.syntaxHighlight};
  padding: 20px;
  border-radius: 10px;

  img {
    width: 100%;
    border-radius: 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    width: 100%;
    padding: 12px;
  }
`;

export const PostWrapperCss = styled.div`
  padding: 50px 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    flex-direction: column-reverse;
    padding: 20px 15px;

    &:nth-child(even) {
      flex-direction: column-reverse;
    }
  }
`;
