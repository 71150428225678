import React from 'react';
import { ProjectsWrapperCss, ProjectsPostWrapperCss } from './index.css';
import ProjectPost from './ProjectPost';

const Projects = ({ posts }) => {
  return (
    <ProjectsWrapperCss>
      <h1>Projects</h1>

      <ProjectsPostWrapperCss>
        {posts.map(post => (
          <ProjectPost postData={post.node} key={post.node.id} />
        ))}
      </ProjectsPostWrapperCss>
    </ProjectsWrapperCss>
  );
};

export default Projects;
