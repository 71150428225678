import React from 'react';
import Layout from 'components/layout';
import ProjectsComponent from 'components/Projects';
import { graphql } from 'gatsby';
import SEO from '../utils/seo';

const Projects = ({ data }) => {
  const posts = data.allMdx.edges;
  return (
    <Layout>
      <SEO title="Projects" />
      <ProjectsComponent posts={posts} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProjectsIndexQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true }, type: { in: ["projects"] } }
      }
    ) {
      edges {
        node {
          id
          body
          excerpt(pruneLength: 180, truncate: true)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM/DD/YYYY")
            title
            tags
            type
            excerpt
            thumbnail
            draft
          }
        }
      }
    }
  }
`;

export default Projects;
