import React from 'react';
import { PostWrapperCss, LeftSideCss, RightSideCss } from './index.css';
import { Link } from 'gatsby';

const ProjectPost = ({ postData }) => {
  return (
    <PostWrapperCss>
      <LeftSideCss>
        <h2>{postData.frontmatter.title}</h2>
        <h4>{postData.frontmatter.excerpt}</h4>
      </LeftSideCss>
      <RightSideCss>
        <Link to={postData.fields.slug}>
          <img
            src={require(`../../images/${postData.frontmatter.thumbnail}`)}
            alt="thumbnail"
          />
        </Link>
      </RightSideCss>
    </PostWrapperCss>
  );
};

export default ProjectPost;
